@font-face {
  font-family: "Rubik";
  src: url(/static/media/Rubik-Light.90a01309.ttf) format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Rubik";
  src: url(/static/media/Rubik-Regular.6840830e.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Rubik";
  src: url(/static/media/Rubik-Medium.1ba21d98.ttf) format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Rubik";
  src: url(/static/media/Rubik-Bold.493f2062.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Rubik";
  src: url(/static/media/Rubik-Black.5bcba2e6.ttf) format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "COCOGOOSELETTERPRESS";
  src: url(/static/media/COCOGOOSELETTERPRESS.4d309d1a.ttf) format("truetype");
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #212121;
  background-color: #ececec !important;
  background: #ececec;
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toast-body {
  font-family: Rubik;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #9351cd;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.primary-btn {
  background-color: #ff8a23;
  color: #fff;
  font-size: 16px;
  border: none;
  font-weight: 500;
  border-radius: 6px; }

.primary-btn:focus {
  background-color: #ff8a23; }

.primary-btn:hover {
  background-color: #d8751e; }

.outline-btn {
  background-color: transparent;
  color: #ff8a23;
  font-size: 16px;
  border: 1px solid #ff8a23;
  font-weight: 500;
  border-radius: 6px; }

.outline-btn:focus {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff; }

.outline-btn:hover {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff; }

.flex {
  display: flex; }

.flex-row {
  flex-direction: row; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-col {
  flex-direction: column; }

.flex-col-reverse {
  flex-direction: column-reverse; }

.flex-wrap {
  flex-wrap: wrap; }

.items-start {
  align-items: flex-start; }

.items-center {
  align-items: center; }

.items-end {
  align-items: flex-end; }

.justify-start {
  justify-content: flex-start; }

.justify-center {
  justify-content: center; }

.justify-end {
  justify-content: flex-end; }

.justify-between {
  justify-content: space-between; }

.justify-around {
  justify-content: space-around; }

.text-white {
  color: #fff; }

.text-12 {
  font-size: 12px; }

.ant-modal-mask {
  background: rgba(0, 0, 0, 0.92); }

.icon-text {
  display: flex;
  align-items: center; }
  .icon-text svg {
    margin-right: 3px; }

.custom-dropdown {
  background-color: #fff !important;
  border-radius: 0 !important;
  border: none;
  padding: 0;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06) !important; }
  .custom-dropdown:before {
    display: none;
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    background: #f0eff1;
    width: 100%;
    height: 20px; }
  .custom-dropdown.ant-select-dropdown-placement-bottomLeft {
    border-radius: 0 0 20px 20px !important; }
    .custom-dropdown.ant-select-dropdown-placement-bottomLeft:before {
      top: -20px;
      bottom: auto; }
  .custom-dropdown .ant-select-dropdown-menu {
    overflow-x: hidden; }
  .custom-dropdown .ant-select-dropdown-menu-item {
    font-size: 16px;
    font-weight: 500;
    color: #524e59;
    padding: 10px 16px; }
    .custom-dropdown .ant-select-dropdown-menu-item:hover, .custom-dropdown .ant-select-dropdown-menu-item:active, .custom-dropdown .ant-select-dropdown-menu-item:focus, .custom-dropdown .ant-select-dropdown-menu-item-active {
      background-color: #4C1A73;
      color: #fff; }

.component-loader {
  text-align: center;
  width: 100%; }

.three-dots {
  padding: 5px; }

.three-dots .dot {
  width: 20px;
  height: 20px;
  background-color: transparent;
  position: relative;
  display: inline-block; }

.three-dots .dot::after {
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: lightgrey;
  display: inline-block;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-duration: 900ms;
          animation-duration: 900ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.three-dots .dot:nth-child(2)::after {
  -webkit-animation-delay: 150ms;
          animation-delay: 150ms; }

.three-dots .dot:nth-child(3)::after {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms; }

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); } }

@keyframes scale {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); } }

